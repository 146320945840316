var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["supervisor-block-edit-quota", { show: _vm.isShow }] },
    [
      _c(
        "div",
        { staticClass: "supervisor-block-edit-quota__content" },
        [
          _c("div", { staticClass: "supervisor-block-edit-quota__header" }, [
            _c("div", { staticClass: "supervisor-block-edit-quota__title" }, [
              _vm._v("РЕДАКТИРОВАНИЕ"),
            ]),
            _c("div", [
              _c("i", {
                staticClass: "el-icon-close supervisor-block-edit-quota__close",
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
            ]),
          ]),
          _vm._l(_vm.quotas, function (item) {
            return _c(
              "div",
              { key: item.id },
              [
                _c("form-edit-quota", {
                  attrs: {
                    "item-quota": item,
                    deleted: _vm.deleted,
                    edit: _vm.edit,
                    add: _vm.add,
                    "send-request": _vm.sendRequest,
                  },
                  on: {
                    delete: _vm.listenDelete,
                    editQuota: _vm.listenEdit,
                    add: _vm.listenAdd,
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "supervisor-block-edit-quota__btns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "form-edit-quota__btn",
                  attrs: { type: "warning" },
                  on: { click: _vm.handleDeletedQuota },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.add && _vm.quotas.length > 1
                          ? "Удалить квоты"
                          : "Удалить квоту"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-edit-quota__btn",
                  attrs: { type: "warning" },
                  on: { click: _vm.handleClickSave },
                },
                [_vm._v(" Сохранить ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }